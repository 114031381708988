<template>
  <Loading v-if="$store.state.loader" />
  <notifications  max="1" :class="[this.$i18n.locale == 'ar' ? 'ar':'en']" position="top left" />
  <main id="back"
  :class="[this.$i18n.locale == 'ar' ? 'ar-dir':'en-dir',switchHeader ? 'light':'']">
      <Header v-if="!switchHeader"  />
      <HeaderProfile v-else />
        <!-- Use a custom transition or fallback to `fade` -->
        <Transition name="fade-page" mode="out-in">
          <router-view />
        </Transition>
      <Footer v-if="!switchHeader" v-show="!$route.path.includes('login') || !$route.path.includes('register')" />
  </main>
</template>
<script>
// @ is an alias to /src
import { defineAsyncComponent } from 'vue';
export default {
  name: 'app',
  data() {
    return {
    };
  },
  computed:{
        switchHeader(){
            if(this.$route.path.includes('profile')){
                return true
            }
            else{
                return false
            }
        },
       
    },
  components: {
    Loading: defineAsyncComponent( () => import('@/components/Global/Loading.vue') ),
    Header: defineAsyncComponent( () => import('@/components/Common/Client/Header.vue') ),
    HeaderProfile: defineAsyncComponent( () => import('@/components/Common/Profile/HeaderProfile.vue') ),
    Footer: defineAsyncComponent( () => import('@/components/Common/Client/Footer.vue') ),
  },
  watch:{
    $route (to, from){
      $("html, body").animate({scrollTop: 0}, 500);
    }
} ,
mounted(){
  const backToTopButton = document.createElement('button');
  backToTopButton.textContent = 'Back to Top';
  backToTopButton.classList.add('back-to-home');
  backToTopButton.innerHTML =`
  <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="1.24023" width="35" height="35" rx="4.5" stroke="transparent"/>
  <path d="M13.6796 18.0331L17.9293 13.7401L16.9395 12.7402L11 18.7402L11.4949 19.2402L16.9395 24.7402L17.9293 23.7404L13.6796 19.4474H25V18.0331H13.6796Z" fill="white"/>
</svg>
  `
  document.getElementById('back').appendChild(backToTopButton);

  backToTopButton.addEventListener('click', function() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
});

window.addEventListener('scroll', function() {
  const middleOfScreen = window.innerHeight * 0.7;
  const scrollPosition = window.scrollY;

  if (scrollPosition > middleOfScreen) {
    backToTopButton.classList.add('show');
  } else {
    backToTopButton.classList.remove('show');
  }
});
}
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
.fade-page-enter-active,
.fade-page-leave-active {
  transition: 0.6s ease;
}
.fade-page-enter-from,
.fade-page-leave-to {
  transform: translateY(-100px);
  
}
.ar-dir{
  direction: rtl;
  #nav{
    right: 0;
  }
}
.en-dir{
  #nav{
    left: 0;
  }
}
#app {
  font-family: "Tajawal";
}
</style>
