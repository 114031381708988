import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
// import languages file
import ar from './assets/i18n/ar.json'
import en from './assets/i18n/en.json'
import App from './App.vue'
import 'animate.css';
// import router vue
import router from './router'
// import store state management
import store from './store'
// import styles
import './assets/styles/main.scss'
// import swiper
import { Swiper, SwiperSlide } from "swiper/vue";
import './assets/swiper/navigation.scss'
import './assets/swiper/pagination.scss'
import "swiper/swiper.scss";
import '../node_modules/intl-tel-input/build/css/intlTelInput.css'
import SwiperCore, { Navigation, Pagination,Autoplay } from "swiper";
SwiperCore.use([Navigation, Pagination,Autoplay]);
// import AOS Animation
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

  });
// import Cookies
import Cookie from 'cookie-universal'
const cookie = Cookie()
// import jQuery
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;
// create i18n varible for use globaly
const i18n = createI18n({
    messages: {
        ar: ar,
        en: en,
    },
    locale: cookie.get('lang'),
    fallbackLocale: 'ar' // set fallback locale
})
// impost axios

import axios from 'axios';
axios.defaults.baseURL = 'https://dev.etmaam.com.sa/';
axios.defaults.headers.common['language'] = cookie.get('lang');
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['referrerPolicy'] = 'no-referrer';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers.common['Authorization'] = `Bearer ${cookie.get('Userdata').token}`;
// axios.defaults.headers.common = {
//     'Accept': 'application/json',
//     'Access-Control-Allow-Origin': '*',
//     'Access-Control-Request-Method':'*',
//     'Access-Control-Allow-Credentials' :'true',
//     'Content-type': 'application/json',
//     'language':cookie.get('lang')
//   };
// import Notifications;
import Notifications from '@kyvg/vue3-notification';


createApp(App).component("Swiper", Swiper)
.component("SwiperSlide", SwiperSlide)
.use(store).use(router).use(i18n).use(Notifications).mount('#app');
